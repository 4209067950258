<template>
<div class="main">
  <VxeOrgTree service="/sys/sysDepartment" class="main_left" :defaultTrigger="true" :expandAll="true" />
  <div class="main_right">
    <div class="iframe" v-if="linkAddress">
      <iframe class="iframe" :src="linkAddress" v-if="linkAddress" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      linkAddress: ""
    }
  },
  methods: {
    handleNodeClick(data) {
      this.linkAddress = `${this.$route.meta.linkAddress}?source=property&communityId=${data.id}&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJqYXZhIiwidXNlck5hbWUiOiLml6Dlv6fmtYvor5UiLCJqdGkiOiIzMDk4MTJjYTY4MmE0NmMzYTU1ZWQ1MzZhMmFmMGIxMyJ9.mzfShCZq_zMWQA9vSwCltpjZa37D62tDif5LpL692fk`
    },
  },
}
</script>

<style>
.iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
